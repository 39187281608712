import { useEffect } from "react";
import { Navigate, useNavigate, useLocation, Outlet } from "react-router-dom";
import { RootState } from "@root/store/store";
import { useDispatch, useSelector } from "react-redux";
import useLogin from "@root/hooks/useLogin";
import { setUser } from "@root/store/counter/userInfoSlice";
import { setIsAuthenticated } from "@root/store/counter/loginSlice";
import useDashboardData from "@root/hooks/useDashboardData";
import { setTabNumber } from "@root/store/counter/changeTabSlice";
import { clearTransactionCashD } from "@root/store/counter/transactionsCashDSlice";
import { setExchangeCompleted } from "@root/store/counter/checkExchangeCompletedCashDSlice";
import { clearDeductionCashD } from "@root/store/counter/deductionsCashDSlice";
import { SnackBar } from "@root/components/auth";
import { useToast } from "@root/hooks/useToast";

const PrivateRoute = () => {
  const location = useLocation();
  const user: any = useSelector((state: RootState) => state.userInfo.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const passedWelcome = localStorage.getItem("passedWelcome");
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId")

  const {
    getWithdrawalConditions,
    setGetWithdrawalConditionsDone,
    setIsGetDashBoardDone,
  } = useDashboardData();
  const {
    openToast,
    handleOpenToast,
    handleCloseToast,
    alertSeverity,
    toastMessage,
  } = useToast();
  const { getUserProfile, handleLogout } = useLogin();

  useEffect(() => {
    if(!userId){
      handleOpenToast("Session Expired, Please Login Again","error");
      localStorage.removeItem("token");
      localStorage.removeItem("userInfor");
      <SnackBar {...{ openToast, handleCloseToast, alertSeverity, toastMessage }} /> // NOSONAR
      navigate("/login");
    } else if(!token){
      handleOpenToast("Session Expired, Please Login Again","error");
      localStorage.removeItem("userId");
      localStorage.removeItem("userInfor");
      <SnackBar {...{ openToast, handleCloseToast, alertSeverity, toastMessage }} /> // NOSONAR
      navigate("/login");
    } else{
      if (!user || user === null || user === undefined) {
        const getProfileUserFunc = async () => {
          let userId: any = localStorage.getItem("userId");
          let dataUserProfile: any = await getUserProfile(
            userId
          );
          if (dataUserProfile?.success === false) {
            dispatch(setIsAuthenticated(false));
            handleLogout();
          } else {
            setIsGetDashBoardDone(true);
            dispatch(setUser(dataUserProfile));
            dispatch(setIsAuthenticated(true));
          }
          let userInfor : any = localStorage.getItem("userInfor");
          let userInforDataParsed = JSON.parse(userInfor);
          setGetWithdrawalConditionsDone(true);
          getWithdrawalConditions(
            userInforDataParsed?.staff_id
          ).then(() => {
            setGetWithdrawalConditionsDone(false);
          });
        };
        getProfileUserFunc().catch((error) => {
          console.log(error);
          dispatch(setIsAuthenticated(false));
          navigate("/login");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("userInfor");
          dispatch(setTabNumber(0));
          dispatch(clearTransactionCashD([]));
          dispatch(setExchangeCompleted(false));
          dispatch(clearDeductionCashD([]));
        });
      }
    }
  }, [alertSeverity, dispatch, getUserProfile, getWithdrawalConditions, handleCloseToast, handleLogout, handleOpenToast, navigate, openToast, setGetWithdrawalConditionsDone, setIsGetDashBoardDone, toastMessage, token, user, userId]);

  if (passedWelcome === "passed" && !token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <Outlet />;
};

export default PrivateRoute;
